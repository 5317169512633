@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Montserrat&display=swap);
*{
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}

.wrapper{
	margin-top: 70px;
}

.nav-active{
	font-weight: bold;
	color: #000 !important;
}

.navbar{
	background-color: #fff;
	box-shadow: 0 2px 18px 0 rgba(0,0,0,.2)
}

.navbar-brand{
	font-family: 'Kaushan Script', cursive !important;
	font-weight: bold;
}

.request-demo-btn{
	background-color: #fff;
	color: #fff !important;
	border-radius: 5px;
}

.tabHeading{
	text-align: center;
	margin-bottom: 50px;
}

.tabHeading span{
	font-size: 30px;
	font-weight: bold;
	/*border-top:2px solid #000;
	border-bottom:2px solid #000;
	padding: 10px 0 10px 0;*/
}


.topNavMenu{
	margin: 5px 10px;
	cursor: pointer;
}

#MainHeader{
	margin-bottom: 100px;
	cursor: -webkit-grab;
	cursor: grab;
}

.headerSliderSection{
	padding: 100px 100px;
}

.homeHeaderLeft{
	padding-top: 100px;
}

.homeHeaderLeft h1{
	font-weight: bold;
}

.homeHeaderRight{
}

.homeHeaderRight img{
	width: 80%;
	margin: 0 auto;
}
@media screen and (max-width: 1400px) {
  .homeHeaderLeft{
	padding-top: 10px;
	}
}

@media screen and (max-width: 992px) {
  .homeHeaderLeft{
	padding-top: 100px;
	text-align: center;
	}
	.homeHeaderRight img{
	width: 100%;
	}
	.headerSliderSection{
		padding: 10px 10px;
	}

	.homeHeaderLeft{
		padding-top: 50px;
	}
}

.getstartedbtn{
	margin-top: 10px;
	border-radius: 3px !important;
}

.mockup{
	width: 100%;
	border: 10px solid #fff;
}

.invoices-tab{
	background-color: #eee;
	border-right: 5px solid #fff;
	border-left: 10px solid #fff;
	margin-bottom: 10px;
}

.reports-tab{
	background-color: #eee;
	border-left: 5px solid #fff;
	border-right: 10px solid #fff;
	margin-bottom: 10px;
}

.features-tab{
	background-color: #fff;
	border-right: 10px solid #fff;
	border-left: 10px solid #fff;
	margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
	.mockup{
		border: 5px solid #fff;
	}
	.invoices-tab{
	  	border:0;
	  	margin-bottom: 5px;
		border-left: 5px solid #fff;
		border-right: 5px solid #fff;
	}

	.reports-tab{
		border:0;
		border-left: 5px solid #fff;
		border-right: 5px solid #fff;
  		margin-bottom: 5px;
	}

	.features-tab{
		border-left: 5px solid #fff;
		border-right: 5px solid #fff;
		margin-bottom: 5px;
	}
}

.invoices-text h3{
	font-weight: bold;
}

.specificationsTab{
	margin-bottom: 50px;
}

footer{
	background-color: #eee;
}

footer p{
	font-size: 12px;
	font-weight: bold;
}

.featureslist-box{
}

.features-list{
	padding-top: 30px;
}

.features-list ul{
	list-style-type: circle;
	list-style-type: circle !important;
}

.features-list ul li{
	font-weight: bold;
	font-size: 14px;
	list-style-type: circle !important;
}

@media screen and (max-width: 768px) {
	.features-list{
	}

}

.formBody{
	display: none;
}

.alertBody{
	display: none;
}

.requestdemobtn{
	box-shadow: none !important;
	border-radius: 0 !important;
	margin: 5px 10px;
	color: #fff !important;
	border: 2px solid #000 !important;
}

.requestloginbtn{
	box-shadow: none !important;
	border-radius: 0 !important;
	margin: 5px 10px;
	color: #000 !important;
	border: 2px solid #000 !important;
}

.btn{
	box-shadow: none !important;
	border-radius: 0 !important;
}

.alert{
	border-radius: 0 !important;
}

.modal-content{
	border-radius: 0 !important;
}

.form-control{
	border-radius: 0 !important;
}

label{
	font-weight: bold !important;
	font-size: 12px !important;
}

.close{
	color:red !important;
	font-size: 40px !important;
}

.navbar-toggler{
	border: 0 !important;
}

.modal-title{
	font-weight: bold;
}

.flag-text{
	font-weight: bold !important;
	font-size: 10px !important;
}

.flag-icon{
	font-size: 10px;
}
